import React from "react";
import { PodcastForm } from "../features/podcasts/PodcastForm";
import { useGetPodcastsQuery } from "../features/api/apiSlice";
import { Serial } from "../features/api/interfaces";
import { ListPage } from "../features/components/ListPage";
import { Button, Col, Row } from "react-bootstrap";
import { getEnv } from "../app/utils";
import { Rss } from "react-bootstrap-icons";

function getPodcastRssHref(podcast_id: string): string {
  let url = getEnv("REACT_APP_BACKEND_API_URL");
  if (url.endsWith("/")) {
    url = url.slice(0, -1);
  }
  return url + "/serial/" + podcast_id + "/rss";
}

interface PodcastListItemProps {
  obj: Serial;
}

function PodcastListItemSummary(props: PodcastListItemProps) {
  return (
    <React.Fragment>
      <Row>
        <Col>
        </Col>
        <Col xs="auto">
          <Button variant="primary" href={getPodcastRssHref(props.obj.id)} className="plausible-event-name=Episode+Manual+Download">
            <Rss />
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  );
}

function PodcastListItemDetail(props: PodcastListItemProps) {
  return (
    <React.Fragment>
      ID: {props.obj.id}
      <br />
      Name: {props.obj.name}
    </React.Fragment>
  );
}

function Podcasts() {
  const res = useGetPodcastsQuery();

  if (res.isLoading) {
    //content = <Spinner text="Loading..." />
  } else if (res.isSuccess) {
    // content = res.data.map((pod: Serial) => <ListGroup.Item key={pod.id}>{pod.name + " (" + pod.id + ")"}</ListGroup.Item>)
  } else if (res.isError && res.error) {
    // content = <div>{res.error.toString()}</div>
  }

  if (res.isSuccess) {
    return (
      <ListPage
        id="podcasts"
        objName="Podcast"
        objNamePlural="Podcasts"
        objs={res.data}
        meta={{}}
        AddObjForm={PodcastForm}
        EditObjForm={PodcastForm}
        ObjSummaryComponent={PodcastListItemSummary}
        ObjDetailComponent={PodcastListItemDetail}
      />
    );
  }
  return <React.Fragment></React.Fragment>;
}

export default Podcasts;
